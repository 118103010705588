/**
 * Third-party libraries.
 */
import { Skeleton } from "antd";
import { useMemo } from "react";

/**
 * Project components.
 */
import { CommunicationLogRouting } from "@/components/client/communication-log/types";
import { DateUtility } from "@/components/client/date";
import { CallRoutingStatus } from "@/components/client/graphql";
import { Icon } from "@/components/client/icon";
// =============================================================================
// Call Routing Skeleton
// =============================================================================

/**
 * Call Routing Card Skeleton Props.
 */
type CallRoutingCardSkeletonProps = {
  /**
   * Indicates that the call routing card skeleton is visible.
   */
  visible?: boolean;
};

/**
 * A loading skeleton for the call routing card item.
 */
function CallRoutingCardSkeleton({ visible }: CallRoutingCardSkeletonProps) {
  return (
    <div
      className={`w-full h-full flex flex-col gap-4 overflow-y-scroll ${
        visible ? "" : "hidden"
      }`}
    >
      <div className="justify-start items-center gap-1 flex w-full">
        <Skeleton.Avatar size="default" active />
        <div className="flex flex-col gap-2 w-full">
          <Skeleton.Input size="small" active block />
          <Skeleton.Input size="small" active block />
        </div>
      </div>
    </div>
  );
}

// =============================================================================
// Call Routing Item
// =============================================================================

/**
 * Call Routing Item Props.
 */
type CallRoutingItemProps = {
  /**
   * Data to be displayed in the call routing item.
   */
  data: CommunicationLogRouting;
};

/**
 * An entry in the call routing card which denotes 1 routing history.
 *
 * Includes the following details:
 * - Name of the agent the call was routed to.
 * - Routing status.
 * - Routing date.
 */
function CallRoutingItem({ data }: CallRoutingItemProps) {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================
  /**
   * The colors to display based on the status of the communication.
   * This includes the following:
   * - Background color of the card
   * - Icon color
   * - Text color
   */
  const iconColor = useMemo(() => {
    switch (data.status) {
      case CallRoutingStatus.Accepted:
        return "text-semantic-green";
      case CallRoutingStatus.Assigned:
      case CallRoutingStatus.Dialed:
        return "text-primary-navy-main";
      case CallRoutingStatus.Missed:
      case CallRoutingStatus.Rejected:
        return "text-semantic-red";
      default:
        throw new Error(`Unknown status: ${data.status}`);
    }
  }, [data.status]);

  return (
    <div
      key={data.id}
      className="self-stretch justify-between items-center inline-flex"
    >
      <div className="justify-start items-center gap-1 flex">
        <Icon src="phone" className={`!${iconColor}`} />
        <div className="flex-col justify-center items-start inline-flex">
          <div className="text-[#4f537b] text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
            {data.agentName}
          </div>
          <div className="text-[#a7a9bd] text-sm font-normal font-['Mont-Regular'] leading-[17.50px]">
            {data.status}
          </div>
        </div>
      </div>
      <div className="text-[#4f537b] text-sm font-normal font-['Mont-Regular'] leading-[17.50px]">
        {DateUtility.getTime({
          date: data.date,
        })}
      </div>
    </div>
  );
}

// =============================================================================
// Call Routing Items
// =============================================================================

/**
 * Call Routing Items Props.
 */
type CallRoutingItemsProps = {
  /**
   * The data to display in the call routing items.
   */
  data?: CallRoutingItemProps["data"][];
  /**
   * Indicates that the call routing items are loading.
   * Displays a skeleton in place of the items.
   */
  loading?: boolean;
};

/**
 * Display multiple call routing items in a card.
 */
function CallRoutingItems({ data, loading = true }: CallRoutingItemsProps) {
  /**
   * All the call routings that are not an assignment to an agent.
   * We don't need to display assignment routes.
   */
  const filteredRoutings = useMemo(
    () =>
      data?.filter((routing) => routing.status !== CallRoutingStatus.Assigned),
    [data]
  );

  return (
    <div className="self-stretch p-4 flex-col justify-start items-start gap-4 flex max-h-[220px] overflow-scroll">
      <CallRoutingCardSkeleton visible={loading} />
      {!loading && !filteredRoutings?.length && (
        <div className="text-primary-navy-main text-sm font-normal font-['Mont-Regular']">
          No Available Agents
        </div>
      )}
      {!loading &&
        filteredRoutings?.map((routing) => (
          <CallRoutingItem key={routing.id} data={routing} />
        ))}
    </div>
  );
}

// =============================================================================
// Call Routing Card
// =============================================================================

/**
 * Call Routing Card Props.
 */
export type CallRoutingCardProps = {
  /**
   * Indicates that the call routing records are being loaded.
   *
   * Shows a skeleton in place of the records.
   */
  loading?: boolean;
  /**
   * The routing history of the call.
   */
  routings: CallRoutingItemProps["data"][];
  /**
   * Indicates that the call routing card is visible.
   */
  visible?: boolean;
};

/**
 * A small card that lists down the routing history of a call.
 */
export function CallRoutingCard({
  loading,
  routings,
  visible,
}: CallRoutingCardProps) {
  // ===========================================================================
  // ===========================================================================
  // Contexts
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Queries
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!visible) {
    return null;
  }

  return (
    <div
      className={`w-full self-stretch h-max-[212px] bg-white rounded-md border border-[#e8ebf1] flex-col justify-start items-start flex animate-slide-left`}
    >
      <div
        className="self-stretch px-4 py-3 border-b border-[#e8ebf1] flex-col justify-start items-start gap-4 flex"
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="text-primary-navy-main text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
          Routing History
        </div>
      </div>
      <CallRoutingItems data={routings} loading={loading} />
    </div>
  );
}

export function CallNotesCard() {
  return (
    <div className="self-stretch h-[182px] bg-white rounded-md border border-slate-200 flex-col justify-start items-start flex">
      <div className="self-stretch h-[50px] p-4 border-b border-slate-200 flex-col justify-start items-start gap-4 flex">
        <div className="text-slate-600 text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
          Notes
        </div>
      </div>
      <div className="self-stretch h-[132px] p-4 flex-col justify-start items-start gap-4 flex">
        <div className="self-stretch h-[100px] flex-col justify-center items-center gap-2 flex">
          <div className="w-16 h-10 relative">
            <div className="w-[46px] h-[34.74px] left-[9px] top-[1px] absolute"></div>
          </div>
          <div className="text-center text-gray-400 text-sm font-normal font-['Mont-Regular'] leading-snug">
            No Notes
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Third-party libraries.
 */
import { Call } from "@twilio/voice-sdk";
import { Button } from "antd";
import { useEffect, useState } from "react";

/**
 * Project components.
 */
import { CommunicationDirection } from "@/components/client/communication-log";
import { CommunicationLog } from "@/components/client/communication-log/types";
import { Icon } from "@/components/client/icon";
import { User } from "@/components/client/images";
import { useTwilioContext } from "@/components/client/twilio";
import { TimeUtility } from "@/components/common/time";

/**
 * Call control card properties.
 */
export type CallControlLiveCard = {
  data: CommunicationLog | null;
  /**
   * Callbacks for the call control card.
   */
  callback?: {
    onRefresh?: () => void;
    onMute: () => void;
    onPause?: () => void;
    onEnd: () => void;
  };
  /**
   * Indicates that the call control card is visible.
   */
  visible?: boolean;
};

/**
 * Active call control card.
 * Includes a timer, client phone number, and call control buttons (mute, end call).
 */
export function CallControlLiveCard({
  callback,
  data,
  visible = false,
}: CallControlLiveCard) {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { calls: twilioCalls, getCall } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * Active Twilio call associated with this communication log card.
   *
   * This could be null if there is no active call.
   *
   * Active call means the call is connected to the Twilio server.
   */
  const [call, setCall] = useState<Call | null>(null);

  /**
   * Duration of the call in milliseconds
   */
  const [duration, setDuration] = useState(0);

  const [muted, setMuted] = useState(false);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  useEffect(() => {
    if (!data?.dateStarted) {
      return;
    }

    const interval = setInterval(() => {
      setDuration(() => new Date().getTime() - data.dateStarted!.getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [data?.dateStarted]);

  /**
   * Links the Twilio call to this card if applicable.
   * This enables the hangup and mute buttons to work.
   */
  useEffect(() => {
    if (!data?.callSid) {
      setCall(null);
      return;
    }

    setCall(getCall({ callSid: data?.callSid }));
  }, [data?.callSid, getCall]);

  useEffect(() => {
    if (!call) {
      return;
    }

    setMuted(call.isMuted());
  }, [call]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!data || !visible || !call) {
    return null;
  }

  return (
    <div className="self-stretch h-[276px] p-[30px] bg-white flex-col justify-center items-center gap-4 flex animate-slide-left">
      <div className="self-stretch h-40 flex-col justify-center items-center gap-2 flex">
        <div className="text-slate-600 text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
          Live {TimeUtility.formatDuration({ duration })}
        </div>
        <div className="w-16 h-16 relative bg-slate-100 rounded-[100px]">
          <div className="w-16 h-16 left-0 top-0 absolute bg-slate-100 rounded-full overflow-hidden">
            <User className="w-14 h-14 left-[4px] top-[8px] absolute" />
          </div>
        </div>
        {/* <div className="self-stretch h-9 flex-col justify-start items-start flex">
          <div className="self-stretch text-center text-slate-600 text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
            [8210] Elle Cheong/ Timothy Cheong (P4 2024), Daisy Cheong (S1,
            2024)
          </div>
        </div> */}
        <div className="self-stretch text-center text-gray-400 text-sm font-normal font-['Mont-Regular'] leading-[17.50px]">
          {data?.direction === CommunicationDirection.INBOUND
            ? data.from
            : data.to}
        </div>
      </div>
      <div className="justify-start items-start gap-2 inline-flex h-10">
        <Button
          icon={<Icon src={muted ? "microphone-mute" : "microphone"} />}
          onClick={() => {
            callback?.onMute();
            call?.mute(!call.isMuted());

            if (call) {
              setMuted(call?.isMuted());
            }
          }}
          shape="circle"
          style={{
            height: 40,
            width: 40,
          }}
          type="default"
        />
        <Button
          icon={<Icon className="!text-white" src="phone-down" />}
          onClick={() => {
            callback?.onEnd();
            call?.disconnect();

            if (call) {
              setCall(null);
            }
          }}
          shape="circle"
          style={{
            backgroundColor: "var(--semantic-red)",
            height: 40,
            width: 40,
          }}
          type="primary"
        />
      </div>
    </div>
  );
}

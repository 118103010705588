"use client";

/**
 * Third-party libraries.
 */

/**
 * Project components.
 */
import {
  CallCompleteCard,
  CallControlLiveCard,
  CallControlRingingCard,
  CallInformationCard,
  CallRoutingCard,
  CallSummaryCard,
} from "@/components/client/call";
import {
  CommunicationLog,
  CommunicationLogStatus,
  useCommunicationLogContext,
} from "@/components/client/communication-log";
import CommunicationLogSelectALogPlaceholder from "@/components/client/communication-log/communication-log-select-a-log-placeholder";
import { useCallCompleteMutation } from "@/components/client/graphql";
import { ApplicationDefaultProtectedPageLayout } from "@/components/client/layout";

/**
 * Home page.
 * This is requires authentication to be accessed.
 */
function HomePage() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  /**
   * Communication log context.
   */
  const {
    communicationLogs,
    communicationLogsLoading,
    selectedCommunicationLog,
    selectedCommunicationLogLoading,
  } = useCommunicationLogContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const [
    completeCall,
    { data: completeCallResponse, loading: callCompleting },
  ] = useCallCompleteMutation();

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <ApplicationDefaultProtectedPageLayout>
      <div className="flex h-full">
        <div className="h-full w-full grid grid-cols-12 grid-rows-1">
          <div className="col-span-6 lg:col-span-4">
            <CommunicationLog data={communicationLogs} onSearch={() => {}} />
          </div>
          <div
            className={`col-span-6 lg:col-span-8 w-full h-full bg-neutral-surface-gray flex-col justify-center items-center inline-flex ${
              !selectedCommunicationLog ? "" : "hidden"
            }`}
          >
            <CommunicationLogSelectALogPlaceholder />
          </div>
          <div
            className={`col-span-6 lg:col-span-4 ${
              !!selectedCommunicationLog ? "" : "hidden"
            }`}
          >
            <div className="w-full h-full p-4 bg-[#fafbff] flex flex-col justify-start items-center gap-4 overflow-y-auto">
              <CallCompleteCard
                callback={{
                  onCompleteCall: async () => {
                    if (!selectedCommunicationLog?.callSid) {
                      return;
                    }

                    await completeCall({
                      variables: {
                        input: {
                          callSid: selectedCommunicationLog.callSid,
                        },
                      },
                    });
                  },
                }}
                data={{
                  callSid: selectedCommunicationLog?.callSid!,
                  clientPhoneNumber:
                    selectedCommunicationLog?.clientPhoneNumber ?? "",
                  // Convert to milliseconds.
                  duration: selectedCommunicationLog?.duration
                    ? selectedCommunicationLog?.duration * 1000
                    : 0,
                }}
                disabled={selectedCommunicationLogLoading || callCompleting}
                loading={callCompleting}
                visible={
                  !!selectedCommunicationLog?.callSid &&
                  selectedCommunicationLog?.status ===
                    CommunicationLogStatus.WRAPPING_UP
                }
              />
              <CallControlLiveCard
                data={selectedCommunicationLog}
                callback={{
                  onEnd: () => {},
                  onMute: () => {},
                }}
                visible={
                  selectedCommunicationLog?.status ===
                  CommunicationLogStatus.ONGOING
                }
              />
              <CallControlRingingCard data={selectedCommunicationLog} />
              <CallInformationCard
                visible={
                  !!selectedCommunicationLog &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.ONGOING &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.WRAPPING_UP
                }
              />
              <CallSummaryCard
                data={{
                  callSid: selectedCommunicationLog?.callSid!,
                  summary: selectedCommunicationLog?.summary,
                }}
                disabled={
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.ONGOING &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.WRAPPING_UP
                }
                visible={
                  !!selectedCommunicationLog?.callSid &&
                  (selectedCommunicationLog?.status ===
                    CommunicationLogStatus.ONGOING ||
                    selectedCommunicationLog?.status ===
                      CommunicationLogStatus.WRAPPING_UP ||
                    selectedCommunicationLog?.status ===
                      CommunicationLogStatus.COMPLETED)
                }
              />
              <CallRoutingCard
                loading={selectedCommunicationLogLoading}
                routings={selectedCommunicationLog?.routings ?? []}
                visible={
                  !!selectedCommunicationLog &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.ONGOING &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.WRAPPING_UP
                }
              />
            </div>
          </div>
        </div>
      </div>
    </ApplicationDefaultProtectedPageLayout>
  );
}

export default HomePage;

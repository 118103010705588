"use client";

import { Button } from "antd";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";

const ReactQuill = dynamic(() => import("react-quill"), {
  ssr: false,
});

import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";

import { useCallUpdateSummaryMutation } from "@/components/client/graphql";

/**
 * Props for the CallSummaryCard component.
 */
type CallSummaryCardProps<V extends boolean = false> = {
  /**
   * The data of the call.
   */
  data: {
    /**
     * The Twilio Call SID.
     */
    callSid: string;
    /**
     * The summary of the call.
     */
    summary?: string;
  };
  /**
   * Determines if the card is disabled.
   */
  disabled?: boolean;
  /**
   * Determines if the card is visible.
   */
  visible?: boolean;
};

/**
 * A card with a text area to input a summary about a call.
 */
export function CallSummaryCard({
  data,
  disabled = false,
  visible,
}: CallSummaryCardProps) {
  const { callSid, summary = "" } = data;
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * Serves as the final storage of the call summary. This is only updated when
   * the "save" button is clicked.
   */
  const [callSummary, setCallSummary] = useState<string>(summary);

  /**
   * A flag that tracks whether the call summary is edited or not.
   */
  const [callSummaryEdited, setCallSummaryEdited] = useState(false);

  /**
   * The content of the call summary editor. This won't be committed until the
   * save button is clicked.
   *
   * This serves as a temporary storage for the editor content. This is reverted
   * when the "cancel" button is clicked.
   */
  const [editorContent, setEditorContent] = useState<string>(summary);

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const [__updateCallSummary, { loading: updatingCallSummary }] =
    useCallUpdateSummaryMutation();

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /**
   * Updates the call summary and sets the state.
   */
  const updateCallSummary = () => {
    __updateCallSummary({
      variables: {
        input: {
          callSid,
          summary: editorContent ?? null,
        },
      },
    }).then(() => {
      setCallSummary(editorContent ?? "");
    });
  };

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  useEffect(() => {
    setCallSummary(summary);
    setCallSummaryEdited(false);
    setEditorContent(summary);
  }, [disabled, summary]);

  /**
   * Shows or hides the "save" and "cancel" buttons.
   *
   * Updates the call record.
   */
  useEffect(() => {
    // Call summary is edited.
    if (editorContent !== callSummary) {
      // Call summary is edited.
      setCallSummaryEdited(true);
    }
    // Call summary is not edited.
    else {
      setCallSummaryEdited(false);
    }
  }, [callSummary, editorContent]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!visible) {
    return null;
  }

  return (
    <div className="self-stretch bg-white rounded-md border border-slate-200 flex-col justify-start items-start flex animate-slide-left">
      <div
        className="self-stretch p-4 border-b border-slate-200 justify-between items-center inline-flex"
        style={{
          borderBottom: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        <div className="text-slate-600 text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
          Call Summary
        </div>
        <div className="flex gap-2">
          {!disabled && (
            <Button
              disabled={!callSummaryEdited || updatingCallSummary}
              onClick={() => {
                setEditorContent(callSummary);
              }}
              tabIndex={3}
            >
              Cancel
            </Button>
          )}
          {!disabled && (
            <Button
              disabled={!callSummaryEdited || updatingCallSummary}
              loading={updatingCallSummary}
              type="primary"
              onClick={updateCallSummary}
              tabIndex={2}
            >
              Save
            </Button>
          )}
        </div>
      </div>
      <div className="self-stretch p-4 flex-col justify-start items-start gap-4 flex">
        <div className={`w-full ${disabled ? "" : "bg-neutral-light-gray"}`}>
          <ReactQuill
            modules={{
              toolbar: [
                [
                  // {
                  //   size: ["small", false, "large"],
                  // },
                  // {
                  //   color: [],
                  // },
                  "bold",
                  "italic",
                  "underline",
                  // "strike",
                ],
                // ["blockquote", { script: "sub" }, { script: "super" }],
                [
                  {
                    list: "ordered",
                  },
                  {
                    list: "bullet",
                  },
                  // {
                  //   indent: "-1",
                  // },
                  // {
                  //   indent: "+1",
                  // },
                  // {
                  //   align: [],
                  // },
                ],
                ["image", "link"],
              ],
            }}
            onChange={(value) => {
              // Empty editor returns an empty paragraph HTML. We're going to replace this with an empty string.
              if (value === "<p><br/></p>" || value === "<p><br></p>") {
                setEditorContent("");
              }
              // Non-empty editor content.
              else {
                setEditorContent(value);
              }
            }}
            onKeyPress={(event) => {
              // Check for Cmd + Enter on Mac or Ctrl + Enter on other platforms
              if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
                event.preventDefault();
                alert("Save");
              }
            }}
            placeholder={"No call summary"}
            readOnly={disabled}
            tabIndex={1}
            theme="bubble"
            value={editorContent}
          />
        </div>
      </div>
    </div>
  );
}

export function CallSummaryTagsCard() {
  return (
    <div className="self-stretch h-[198px] bg-white rounded-md border border-slate-200 flex-col justify-start items-start flex">
      <div className="self-stretch p-4 border-b border-slate-200 justify-between items-center inline-flex">
        <div className="text-slate-600 text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
          Tags
        </div>
      </div>
      <div className="self-stretch h-[148px] p-4 flex-col justify-start items-start gap-4 flex">
        <div className="self-stretch h-[54px] flex-col justify-start items-start gap-1 flex">
          <div className="text-center text-slate-600 text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
            Intent
          </div>
          <div className="self-stretch h-8 px-2 py-1.5 bg-violet-50 rounded border border-slate-200 justify-between items-center inline-flex">
            <div className="grow shrink basis-0 text-gray-400 text-sm font-normal font-['Mont-Regular'] leading-[17.50px]">
              Select
            </div>
            <div className="w-3 h-3 relative" />
          </div>
        </div>
        <div className="flex-col justify-center items-start gap-1 flex">
          <div className="text-center text-slate-600 text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
            Outcome
          </div>
          <div className="justify-center items-center gap-4 inline-flex">
            <div className="bg-white/opacity-0 justify-start items-center gap-2 flex">
              <div className="w-4 h-4 relative">
                <div className="w-4 h-4 left-0 top-0 absolute rounded-full" />
                <div className="w-4 h-4 left-0 top-0 absolute bg-blue-400 rounded-[100px] border border-blue-400" />
                <div className="w-2 h-2 left-[4px] top-[4px] absolute bg-white rounded-[20px]" />
              </div>
              <div className="text-slate-600 text-sm font-normal font-['Mont-Regular'] leading-normal">
                Resolved
              </div>
            </div>
            <div className="bg-white/opacity-0 justify-start items-center gap-2 flex">
              <div className="w-4 h-4 bg-slate-100 rounded-[100px] border border-slate-100" />
              <div className="text-slate-600 text-sm font-normal font-['Mont-Regular'] leading-normal">
                Unresolved
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useCommunicationLogContext } from "@/components/client/communication-log";
import { DateUtility } from "@/components/client/date";

// =============================================================================
// Call Information Card Field
// =============================================================================

type CallInformationCardFieldProps = {
  /**
   * Label for the field.
   */
  label: string;
  /**
   * Value for the field.
   */
  value?: string | number;
};

/**
 * Displays a single field in the call information card.
 * This is consist of a label and a value.
 */
function CallInformationCardField({
  label,
  value,
}: CallInformationCardFieldProps) {
  return (
    <div>
      <span className="text-[#4f537b] text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
        {label}:
      </span>
      <span className="text-[#4f537b] text-sm font-normal font-['Mont-Regular'] leading-[17.50px]">
        {" " + value}
      </span>
    </div>
  );
}
// =============================================================================
// Call Information Card
// =============================================================================

/**
 * Call Information Card Props.
 */
export type CallInformationCardProps = {
  /**
   * Indicates that the call information card is visible.
   */
  visible?: boolean;
};

/**
 * A small card that displays information about a call.
 */
export function CallInformationCard({ visible }: CallInformationCardProps) {
  // ===========================================================================
  // ===========================================================================
  // Contexts
  // ===========================================================================
  // ===========================================================================

  const { selectedCommunicationLog } = useCommunicationLogContext();

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!visible) {
    return null;
  }

  return (
    <div
      className={`self-stretch bg-white rounded-md border border-[#e8ebf1] flex-col justify-start items-start flex animate-slide-left ${
        !!selectedCommunicationLog ? "" : "hidden"
      }`}
    >
      <div
        className="self-stretch px-4 py-3 flex-col justify-start items-start gap-4 flex"
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="text-[#4f537b] text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
          Call Information
        </div>
      </div>
      <div className="self-stretch p-4 flex-col justify-start items-start gap-2 flex">
        <CallInformationCardField
          label="From"
          value={selectedCommunicationLog?.from}
        />
        <CallInformationCardField
          label="To"
          value={selectedCommunicationLog?.to}
        />
        <CallInformationCardField
          label="Agent"
          value={
            selectedCommunicationLog?.agentName?.length
              ? selectedCommunicationLog.agentName
              : "-"
          }
        />
        <CallInformationCardField
          label="Date"
          value={
            selectedCommunicationLog?.date
              ? DateUtility.getDate({
                  date: selectedCommunicationLog?.date,
                })
              : "-"
          }
        />
        <CallInformationCardField
          label="Start"
          value={
            selectedCommunicationLog?.dateStarted
              ? DateUtility.getTime({
                  date: selectedCommunicationLog?.dateStarted,
                })
              : "-"
          }
        />
        <CallInformationCardField
          label="End"
          value={
            selectedCommunicationLog?.dateEnded
              ? DateUtility.getTime({
                  date: selectedCommunicationLog?.dateEnded,
                })
              : "-"
          }
        />
        <CallInformationCardField
          label="Direction"
          value={selectedCommunicationLog?.direction}
        />
        <CallInformationCardField
          label="Duration"
          value={selectedCommunicationLog?.duration + "s"}
        />
      </div>
    </div>
  );
}

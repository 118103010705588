/**
 * Third-party libraries.
 */
import { Button } from "antd";

/**
 * Project components.
 */
import {
  CommunicationDirection,
  CommunicationLogStatus,
} from "@/components/client/communication-log";
import { CommunicationLog } from "@/components/client/communication-log/types";
import {
  useCallAcceptMutation,
  useCallHangUpMutation,
  useCallRejectMutation,
} from "@/components/client/graphql";
import { Icon } from "@/components/client/icon";

/**
 * Call control ringing card properties.
 */
export type CallControlRingingCardProps = {
  /**
   * The selected communication log.
   */
  data: CommunicationLog | null;
};

/**
 * Displays a card with the ringing call details, an accept and reject button.
 */
export function CallControlRingingCard({ data }: CallControlRingingCardProps) {
  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const [acceptCall, { loading: acceptingCall }] = useCallAcceptMutation();
  const [rejectCall, { loading: rejectingCall }] = useCallRejectMutation();
  const [hangupCall, { loading: hangingUpCall }] = useCallHangUpMutation();

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!data || data.status !== CommunicationLogStatus.RINGING) {
    return null;
  }

  return (
    <div className="w-full p-12 bg-white flex-col justify-center items-center gap-4 inline-flex animate-slide-left">
      <div className="self-stretch h-40 flex-col justify-center items-center gap-2 flex">
        <div className="text-slate-600 text-sm font-normal font-Mont-SemiBold leading-17.5">
          {data?.direction === CommunicationDirection.INBOUND
            ? "Incoming"
            : "Outgoing"}{" "}
          Call
        </div>
        <div className="w-16 h-16 relative bg-slate-100 rounded-full flex items-center justify-center">
          <Icon src="person" />
        </div>
        {/* <div className="self-stretch h-9 flex-col justify-start items-start flex">
          <div className="self-stretch text-center text-slate-600 text-sm font-normal font-Mont-SemiBold leading-17.5">
            [8210] Elle Cheong/ Timothy Cheong (P4 2024), Daisy Cheong (S1,
            2024)
          </div>
        </div> */}
        <div className="self-stretch text-center text-gray-400 text-sm font-normal font-Mont-Regular leading-17.5">
          {data?.direction === CommunicationDirection.INBOUND
            ? data?.from
            : data?.to}
        </div>
      </div>
      <div className="justify-start items-start gap-2 inline-flex">
        {data?.direction === CommunicationDirection.INBOUND && (
          <Button
            disabled={acceptingCall || hangingUpCall || rejectingCall}
            loading={acceptingCall}
            icon={<Icon className="!text-white" src="phone" />}
            onClick={async () => {
              if (acceptingCall || hangingUpCall || rejectingCall) {
                return;
              }

              await acceptCall({
                variables: {
                  input: {
                    callSid: data?.callSid,
                  },
                },
              });
            }}
            shape="circle"
            style={{
              backgroundColor: "var(--semantic-green)",

              height: 40,
              width: 40,
            }}
            type="default"
          />
        )}
        <Button
          disabled={acceptingCall || hangingUpCall || rejectingCall}
          loading={hangingUpCall || rejectingCall}
          icon={<Icon className="!text-white" src="phone-down" />}
          onClick={async () => {
            if (acceptingCall || hangingUpCall || rejectingCall) {
              return;
            }

            // Inbound call.
            if (data?.direction === CommunicationDirection.INBOUND) {
              await rejectCall({
                variables: {
                  input: {
                    callSid: data?.callSid,
                  },
                },
              });
            }
            // Outbound call.
            else if (data?.direction === CommunicationDirection.OUTBOUND) {
              await hangupCall({
                variables: {
                  input: {
                    callSid: data?.callSid,
                  },
                },
              });
            }
          }}
          shape="circle"
          style={{
            backgroundColor: "var(--semantic-red)",
            height: 40,
            width: 40,
          }}
          type="primary"
        />
      </div>
    </div>
  );
}
